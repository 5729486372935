// 开发环境域名

const host_development = 'https://platform.gzasync.com'
//const host_development = 'https://ad.dis9.net'

export default {
  // 版本
  version: '2.1.0',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'GVJBZ-MXC6V-2Q3P7-UB5NZ-I6BLE-HZFLY',
  authLicense: 'fgrfdrf'
}
