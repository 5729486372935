import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
import configs from '@/config'
const routes = [{
  path: '/shop',
  name: 'shop',
  meta: { title: '商城' },
  redirect: '/shop/lists',
  component: Main,
  children: [
    {
      path: '/shop/lists',
      name: 'lists',
      meta: {
        title: '商城管理',
        parentPath: '/shop',
        icon: 'icon_ziti_store',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/lists',
      children: [
        {
          path: '/shop/lists',
          name: 'shop_lists',
          meta: {
            title: '商城列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists.vue')
        },
        {
          path: '/shop/lists_detail',
          name: 'lists_detail',
          meta: {
            hidden: true,
            title: '商城信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists_edit.vue')
        },
        {
          path: '/shop/modify_account',
          name: 'modify_account',
          meta: {
            hidden: true,
            title: '修改超级管理员账号',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/modify_account.vue')
        },
        {
          path: '/shop/package_record',
          name: 'package_record',
          meta: {
            hidden: true,
            title: '套餐记录',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/package_record.vue')
        },
        {
          path: '/shop/game_app_lists',
          name: 'game_app_lists',
          meta: {
            hidden: true,
            title: '游戏应用列表',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_app_lists.vue')
        },
        {
          path: '/shop/game_app_detail',
          name: 'game_app_detail',
          meta: {
            hidden: true,
            title: '游戏应用信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_app_edit.vue')
        },
        {
          path: '/shop/game_config',
          name: 'game_config',
          meta: {
            hidden: true,
            title: '修改游戏配置',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_config.vue')
        },
      ]
    },
    {
      path: '/shop/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐管理',
        parentPath: '/shop',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/set_meal',
      children: [
        {
          path: '/shop/set_meal',
          name: 'set_meal',
          meta: {
            title: '商城套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/lists.vue')
        },
        {
          path: '/shop/set_meal/app_package',
          name: 'app_package',
          meta: {
            title: '打包套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/app_package/lists.vue')
        },
        {
          path: '/shop/set_meal_edit/app_package',
          name: 'set_meal_app_package_edit',
          meta: {
            hidden: true,
            title: '打包套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/app_package/edit.vue')
        },
        {
          path: '/shop/set_meal_edit',
          name: 'set_meal_edit',
          meta: {
            hidden: true,
            title: '商城套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/edit.vue')
        }
      ]
    },
    {
      path: '/shop/ui',
      name: 'ui',
      meta: {
        title: 'UI管理',
        parentPath: '/shop',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/ui',
      children: [
        {
          path: '/shop/ui',
          name: 'ui',
          meta: {
            title: 'UI列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/ui/lists.vue')
        },
        {
          path: '/shop/ui_edit',
          name: 'ui_edit',
          meta: {
            hidden: true,
            title: '',
            parentPath: '/shop',
            prevPath: '/shop/ui',
            permission: ['view']
          },
          component: () => import('@/views/shop/ui/ui_edit.vue')
        }
      ]
    },
    // if(configs.authLicense != ""){
    //   path: '/shop/game_shop_deploy_manager',
    //   name: 'shop_deploy_manager',
    //   meta: {
    //     title: '系统授权管理',
    //     parentPath: '/shop',
    //     icon: 'icon_order_guanli',
    //     permission: ['view']
    //   },
    //   component: Blank,
    //   redirect: '/shop/game_shop_deploy_manager',
    //   children: [
    //     {
    //       path: '/shop/game_shop_deploy_manager',
    //       name: 'game_shop_deploy_manager',
    //       meta: {
    //         title: '授权列表',
    //         parentPath: '/shop',
    //         permission: ['view']
    //       },
    //       component: () => import('@/views/shop/game_shop_deploy_manger/lists.vue')
    //     },
    //     {
    //       path: '/shop/game_shop_deploy_manger_edit',
    //       name: 'game_shop_deploy_manger_edit',
    //       meta: {
    //         hidden: true,
    //         title: '',
    //         parentPath: '/shop',
    //         prevPath: '/shop/game_shop_deploy_manger',
    //         permission: ['view']
    //       },
    //       component: () => import('@/views/shop/game_shop_deploy_manger/edit.vue')
    //     }
    //   ]
    // },
    // {
    //   path: '/shop/game_box/list',
    //   name: 'game_box',
    //   meta: {
    //     title: '盒子管理',
    //     parentPath: '/shop',
    //     icon: 'icon_order_guanli',
    //     permission: ['view']
    //   },
    //   component: Blank,
    //   redirect: '/shop/game_box/list',
    //   children: [
    //     {
    //       path: '/shop/game_box/list',
    //       name: 'game_box_list',
    //       meta: {
    //         title: '游戏列表',
    //         parentPath: '/shop',
    //         permission: ['view']
    //       },
    //       component: () => import('@/views/shop/game_box/lists.vue')
    //     },
    //     {
    //       path: '/shop/game_box/category_list',
    //       name: 'game_category_box_list',
    //       meta: {
    //         title: '分类列表',
    //         parentPath: '/shop',
    //         permission: ['view']
    //       },
    //       component: () => import('@/views/shop/game_box/category_lists.vue')
    //     },
    //     {
    //       path: '/shop/game_box/game_edit',
    //       name: 'game_edit',
    //       meta: {
    //         hidden: true,
    //         title: '',
    //         parentPath: '/shop',
    //         prevPath: '/shop/game_box/list',
    //         permission: ['view']
    //       },
    //       component: () => import('@/views/shop/game_box/game_edit.vue')
    //     }
    //   ]
    // }
  ]
}]

const routes2 = [{
  path: '/shop',
  name: 'shop',
  meta: { title: '商城' },
  redirect: '/shop/lists',
  component: Main,
  children: [
    {
      path: '/shop/lists',
      name: 'lists',
      meta: {
        title: '商城管理',
        parentPath: '/shop',
        icon: 'icon_ziti_store',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/lists',
      children: [
        {
          path: '/shop/lists',
          name: 'shop_lists',
          meta: {
            title: '商城列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists.vue')
        },
        {
          path: '/shop/lists_detail',
          name: 'lists_detail',
          meta: {
            hidden: true,
            title: '商城信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists_edit.vue')
        },
        {
          path: '/shop/modify_account',
          name: 'modify_account',
          meta: {
            hidden: true,
            title: '修改超级管理员账号',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/modify_account.vue')
        },
        {
          path: '/shop/package_record',
          name: 'package_record',
          meta: {
            hidden: true,
            title: '套餐记录',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/package_record.vue')
        },
        {
          path: '/shop/game_app_lists',
          name: 'game_app_lists',
          meta: {
            hidden: true,
            title: '游戏应用列表',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_app_lists.vue')
        },
        {
          path: '/shop/game_app_detail',
          name: 'game_app_detail',
          meta: {
            hidden: true,
            title: '游戏应用信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_app_edit.vue')
        },
        {
          path: '/shop/game_config',
          name: 'game_config',
          meta: {
            hidden: true,
            title: '修改游戏配置',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/game_config.vue')
        },
      ]
    },
    {
      path: '/shop/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐管理',
        parentPath: '/shop',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/set_meal',
      children: [
        {
          path: '/shop/set_meal',
          name: 'set_meal',
          meta: {
            title: '商城套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/lists.vue')
        },
        {
          path: '/shop/set_meal/app_package',
          name: 'app_package',
          meta: {
            title: '打包套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/app_package/lists.vue')
        },
        {
          path: '/shop/set_meal_edit/app_package',
          name: 'set_meal_app_package_edit',
          meta: {
            hidden: true,
            title: '打包套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/app_package/edit.vue')
        },
        {
          path: '/shop/set_meal_edit',
          name: 'set_meal_edit',
          meta: {
            hidden: true,
            title: '商城套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/edit.vue')
        }
      ]
    },
    {
      path: '/shop/ui',
      name: 'ui',
      meta: {
        title: 'UI管理',
        parentPath: '/shop',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/ui',
      children: [
        {
          path: '/shop/ui',
          name: 'ui',
          meta: {
            title: 'UI列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/ui/lists.vue')
        },
        {
          path: '/shop/ui_edit',
          name: 'ui_edit',
          meta: {
            hidden: true,
            title: '',
            parentPath: '/shop',
            prevPath: '/shop/ui',
            permission: ['view']
          },
          component: () => import('@/views/shop/ui/ui_edit.vue')
        }
      ]
    },
    {
      path: '/shop/game_shop_deploy_manager',
      name: 'shop_deploy_manager',
      meta: {
        title: '系统授权管理',
        parentPath: '/shop',
        icon: 'icon_order_guanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/game_shop_deploy_manager',
      children: [
        {
          path: '/shop/game_shop_deploy_manager',
          name: 'game_shop_deploy_manager',
          meta: {
            title: '授权列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/game_shop_deploy_manger/lists.vue')
        },
        {
          path: '/shop/game_shop_deploy_manger_edit',
          name: 'game_shop_deploy_manger_edit',
          meta: {
            hidden: true,
            title: '',
            parentPath: '/shop',
            prevPath: '/shop/game_shop_deploy_manger',
            permission: ['view']
          },
          component: () => import('@/views/shop/game_shop_deploy_manger/edit.vue')
        }
      ]
    }
  ]
}]

export default configs.authLicense === '' ? routes : routes2
